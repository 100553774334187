<template>
  <div class="SoftwareActivation">
    <!--激活码-->
    <div class="user-wrap card">
      <SoftwareActivationQuery @queryEnd="queryEnd"></SoftwareActivationQuery>
   </div>
          
  <!--我的软件区域-->
  <el-table :data="memberSoftappList" class="SoftwareActivation-table" style="width: 100%; margin-bottom: 24px;">
    <el-table-column label="Software"  prop="softappName"> </el-table-column>
    <el-table-column label="Activation Date" prop="ctime"> 
      <template slot-scope="scope">
        {{ toUTCDate(scope.row.ctime) }} 
      </template>
    </el-table-column>     
    <el-table-column label="Expiration Date"  prop="dueTime">
      <template slot-scope="scope">
        {{ toUTCDate(scope.row.dueTime) }} 
      </template>
    </el-table-column>     
    <el-table-column width="200">
      <template slot-scope="scope">
        <el-button type="primary" @click="showInfo(scope.row)">Info</el-button>    
      </template>
    </el-table-column>     
  </el-table>   

  <el-dialog  :visible.sync="successDialog" v-if="successDialog" :close-on-click-modal="false" class="success-dialog">
        <div>
          <SoftwareActivationFeature @activeEnd="activeEnd" :memberAuthorizationOrder="memberAuthorizationOrder"></SoftwareActivationFeature>
        </div>
    </el-dialog>

  </div>  
</template>

<script>
import SoftwareActivationQuery from "@/views/SoftwareActivation/query.vue";
import SoftwareActivationFeature from "@/views/SoftwareActivation/feature.vue";
import {
listMemberSoftappHttp
} from "@/api/getData";
import { strToUTCDate} from '@/utils/tool'
export default {
name: 'SoftwareActivationIndex',
components: {SoftwareActivationQuery,SoftwareActivationFeature},
data() {
  return {
    memberSoftappList:[],
    successDialog: false,
    memberAuthorizationOrder:{},
  }
},
mounted() {
  this.listMemberSoftapp()
},
methods:{
  //查询激活码后的方法
  queryEnd(memberAuthorizationOrder){
    this.memberAuthorizationOrder = memberAuthorizationOrder
    this.successDialog  = true
  },
  toUTCDate(str){
    return strToUTCDate(str)
  },
  //使用激活码成功后的方法
  activeEnd(){
    this.listMemberSoftapp()
    this.successDialog = false
  },
  //软件详情
  showInfo(rowData){
    this.$router.push({
        path: '/software-activation-info',
        query: {
            id: rowData.softappId,
        },
    });
  },
  //获取软件列表
  listMemberSoftapp(){
    listMemberSoftappHttp(this.gMemberId).then(({data})=>{
        this.memberSoftappList = data
    })
  },
}  

}
</script>

<style lang="scss">
.SoftwareActivation {
padding-top: 0;
color: #282828;
line-height: 1;
.success-dialog{
  .el-dialog__header{
    background-color: #FFF;
  }
  .success-title{
    color: #C81432;
    font-size: 1.04vw;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
  }
  .success-content{
    margin-top: 36px;
    font-size: 0.83vw;
    font-weight: normal;
    line-height: 28px;
    text-align: center;
    color: #282828;
  }
  .el-dialog__footer {
    margin-top: 30px;
    text-align: center;
  }  
}
  
.red{
  color: #C81432;
  text-decoration:underline;
}
.error_msg{
   color: #C81432;
   font-weight: bold;
   line-height: 50px;
   font-size: 0.83vw;
}
.card {
  border-radius: 10px;
  background: #F9F9F9;
}

.user-wrap {
  height: 126px;
  padding-left: 36px;
  padding-top: 34px;
  margin-bottom: 24px;
  .el-button{
    width: 12.5vw;
  }
  .el-input__inner{
     width: 46.2vw;
  }
}
.SoftwareActivation-table {
  /* 其他样式 */
  /* 设置外边框为圆角 */
  .el-table__body,
  .el-table__header-wrapper {
    border-radius: 10px; /* 根据需要调整圆角大小 */
  }
  .el-table__body .cell {
       font-size: 0.72vw;
       color: #666666;
  }
  .el-table__header .cell {
       font-size: 0.93vw;
       color: #282828;
  }
  .el-button {
    width: 8.4vw;
    height: 48px;
    border-radius: 10px;
    opacity: 1;
    background: #b90025;
    font-size: 0.83vw;
    font-weight: normal;
    span {
      color: #ffffff;
    }
  } 
}
}
</style>
