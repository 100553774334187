<template>
  <div class="SoftwareActivationFeature">
    <el-table :data="orderList" class="SoftwareActivationFeature-table" style="width: 100%; margin-bottom: 24px;">
      <el-table-column label="Activation Code"  prop="authorizationCode"> </el-table-column>
      <el-table-column label="Software" prop="softappName"> </el-table-column>    
    </el-table> 
    <el-table :data="orderFeatureList" max-height="400px" class="SoftwareActivationFeature-table" style="width: 100%; margin-bottom: 24px;">
      <el-table-column label="Function"  prop="featureName"> </el-table-column>
      <el-table-column label="Days" prop="years"> </el-table-column>    
    </el-table>  
    <span slot="footer" class="feature-footer">
      <el-button type="primary" @click="active">Activate the Software with the Code</el-button>
    </span>
  </div>  
</template>

<script>
import {
  useAuthorizationCodeHttp,
} from "@/api/getData";
export default {
name: 'SoftwareActivationFeature',
props:['memberAuthorizationOrder'],
data() {
  return {
    orderFeatureList:[],
    orderList:[]
  }
},
mounted() {
  this.initOrder()
},
methods:{
  //使用激活码
  active(){
    let memberSoftappFeature = {
      userId: this.gMemberId
    }
    useAuthorizationCodeHttp(this.memberAuthorizationOrder.authorizationCode,memberSoftappFeature).then(({data})=>{
       this.$message.success("Activation successful")  
       this.$emit("activeEnd")
    })
  },
  initOrder(){
    this.orderList = []
    this.orderFeatureList = []
    this.orderList.push({
      'authorizationCode':this.memberAuthorizationOrder.authorizationCode,
      'softappName':this.memberAuthorizationOrder.softappName
    })
    this.orderFeatureList = JSON.parse(this.memberAuthorizationOrder.featureJson)
  }
}  

}
</script>

<style lang="scss">
.SoftwareActivationFeature {
  padding-top: 0;
  color: #282828;
  line-height: 1;

  .query-title{
    font-size: 0.93vw;
    font-weight: normal;
    line-height: 20px;
    color: #AAAAAA;
  }
  .feature-footer{
    margin-top: 80px;
    text-align: center;
    margin-left: 30%;
    .el-button {
      width: max-400px;
      height: 48px;
      border-radius: 10px;
      opacity: 1;
			background: #b90025;
			margin-top: 5px;
			font-size: 0.83vw;
			font-weight: normal;
			span {
				color: #ffffff;
			}
		} 
  }
  .SoftwareActivationFeature-table{
    /* 其他样式 */
    /* 设置外边框为圆角 */
    .el-table__body,
    .el-table__header-wrapper {
      border-radius: 10px; /* 根据需要调整圆角大小 */
    }

    .el-table__body .cell {
        font-size: 0.72vw;
        color: #666666;
    }
    .el-table__header .cell {
        font-size: 0.93vw;
        color: #282828;
    }
  }
}
</style>
