export const isObjEmpty = (obj) => { return (obj === null || obj===undefined || Object.keys(obj).length===0)}

/**
 * 获取UTC时间
 * @param {utc} UTCDateString 
 * @returns 
 */
export function strToUTCDate(UTCDateString) {  
  if (!UTCDateString) {  
      return '-';  
  }  

  // 格式化函数  
  function formatFunc(num) {  
      return String(num).padStart(2, '0');  
  }  

  // 尝试解析日期字符串  
  // 如果UTCDateString没有时间和时区，则添加它们  
  var dateStr = `${UTCDateString}Z`; // 添加时间00:00:00和时区Z  
  dateStr  = dateStr.replace(/\s/g,'T').replace(/\//g,'-');
  var date2 = new Date(dateStr);  
  // 检查date2是否是有效的日期对象  
  if (isNaN(date2.getTime())) {  
      return '-'; // 如果不是，返回'-'  
  }  

  var year = date2.getFullYear();  
  var mon = formatFunc(date2.getMonth() + 1);  
  var day = formatFunc(date2.getDate());  
  var hour = formatFunc(date2.getHours());  
  var min = formatFunc(date2.getMinutes());  
  var sec = formatFunc(date2.getSeconds());  

  var dateStrFormatted = `${year}-${mon}-${day} ${hour}:${min}:${sec}`;  
  return dateStrFormatted;  
}