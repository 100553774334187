<template>
  <div class="SoftwareActivationQuery">
      <div class="query-title">Software Activation Code Inquiry</div>
      <el-row>
        <el-col :span="19">
          <el-input v-model="authorizationCode" ></el-input>
        </el-col>
        <el-col :span="5">
          <el-button @click="queryCode">Query</el-button>
        </el-col>
      </el-row>
  </div>  
</template>

<script>
import {
  getOrderByCodeHttp,
} from "@/api/getData";
export default {
name: 'SoftwareActivationQuery',
data() {
  return {
    authorizationCode:""
  }
},
mounted() {
  
},
methods:{
  //查询激活码是否可用
  queryCode(){
    if(!this.authorizationCode.trim()){
      this.$message.error("Software activation code is required");
      return;
    }
    //根据激活码获取激活码订单信息
    getOrderByCodeHttp(this.authorizationCode.trim()).then(({data})=>{
        this.$emit("queryEnd",data)
    })
  }
}  

}
</script>

<style lang="scss">
.SoftwareActivationQuery {
  padding-top: 0;
  color: #282828;
  line-height: 1;

  .query-title{
    font-size: 0.93vw;
    font-weight: normal;
    line-height: 20px;
  }
  .el-input__inner{
      margin-top: 5px;
      width: 46.2vw;
      height: 48px;
      border-radius: 10px;
      opacity: 1;
      box-sizing: border-box;
      border: 1px solid #AAAAAA;
      background-color: #FFF;
  }
  .el-button {
    width: 12.5vw;
    height: 48px;
    border-radius: 10px;
    opacity: 1;
    background: #b90025;
    margin-top: 5px;
    margin-left: -20px;
    font-size: 0.83vw;
    font-weight: normal;
    span {
      color: #ffffff;
    }
  } 
}
</style>
